/* import __COLOCATED_TEMPLATE__ from './platform-nav-bar.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { readOnly, none, match } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';
import RouteRegexes from 'embercom/lib/route-regexes';

export default Component.extend({
  tagName: '',
  classNames: ['flex-none', 'flex', 'flex-row'],
  appService: service(),
  router: service(),
  app: readOnly('appService.app'),
  searchController: ternaryToProperty(
    'isOnCompaniesRoute',
    'companiesController',
    'usersController',
  ),
  noActiveSegment: none('segmentController.model'),
  isOnCompanyRoute: match('router.currentRouteName', RouteRegexes.companyRoute),
  isOnUserRoute: match('router.currentRouteName', RouteRegexes.userRoute),

  actions: {
    toggleSearch() {
      this.searchController.send('toggleSearch');
    },
    exitSearch() {
      this.searchController.send('exitSearch');
    },
  },
});
