/* import __COLOCATED_TEMPLATE__ from './plan-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { MARKETING_PLAN_TRANSLATION_KEYS, PLAN_DATA } from 'embercom/lib/billing';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import type Plan from 'embercom/models/plan';
import type IntlService from 'ember-intl/services/intl';
import type Price from 'embercom/models/price';
import type QuoteService from 'embercom/services/quote-service';

interface Args {
  plan: Plan;
  selectedPlanId: number;
  selectPlan: (planId: number) => void;
  isSinglePlanPaywall: boolean;
  openedFromBillingSummary: boolean;
}

export default class PlanCardComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare customerService: any;
  @service declare paywallService: any;
  @service declare quoteService: QuoteService;
  @service declare appService: any;
  @service declare intercomEventService: any;

  private COMPACT_FEATURE_DISPLAY_LIMIT = 4;

  get isOnGraduatingTrial(): boolean {
    return this.args.plan.active && this.args.plan.activeTrialIsGraduating;
  }

  get isNonGraduatingTrial(): boolean {
    return (
      this.args.plan.active &&
      !this.args.plan.activeTrialIsGraduating &&
      !this.args.plan.billableCustomerPlan
    );
  }

  get shouldUseQuoteService(): boolean {
    return this.paywallService.shouldUseQuoteService(this.args.selectedPlanId);
  }

  get showLoader() {
    return this.shouldUseQuoteService && this.quoteService.loading;
  }

  get isInteractive(): boolean {
    return (
      !this.args.isSinglePlanPaywall &&
      !this.args.plan.billableCustomerPlan &&
      !this.isOnGraduatingTrial &&
      !this.isNonGraduatingTrial
    );
  }

  get isSelected(): boolean {
    return !this.args.isSinglePlanPaywall && this.args.selectedPlanId === this.args.plan.idAsNumber;
  }

  get planName(): string {
    if (this.planData?.planCardHeadingOverride) {
      return this.intl.t(this.planData.planCardHeadingOverride);
    }

    return this.appService.app.hasValueBasedPricing2
      ? this.args.plan.nameWithProduct
      : this.args.plan.name;
  }

  get planData(): any | undefined {
    let planData = this.appService.app.onPricing5
      ? PLAN_DATA[this.args.plan.id]
      : MARKETING_PLAN_TRANSLATION_KEYS[this.args.plan.id];

    return planData;
  }

  get description(): string {
    return this.planData?.descriptionTranslationKey
      ? this.intl.t(this.planData.descriptionTranslationKey)
      : '';
  }

  get marketingFeatureListHeading(): string {
    let marketingFeaturesHeading = this.planData.marketingFeaturesHeadingOverride
      ? this.intl.t(this.planData.marketingFeaturesHeadingOverride)
      : this.intl.t('paywalls.comparison.plan-cards.key_features');

    return marketingFeaturesHeading.toUpperCase();
  }

  get features(): Array<string> | undefined {
    return this.planData?.marketingFeaturesTranslationKeys;
  }

  get featureTextClasses() {
    let classes = 'flex pb-1 dark:text-dm-gray';
    if (this.args.isSinglePlanPaywall && !this.smallNumberOfFeatures) {
      classes += ' w-1/3';
    }
    return classes;
  }

  get smallNumberOfFeatures(): boolean {
    return this.features ? this.features.length <= this.COMPACT_FEATURE_DISPLAY_LIMIT : false;
  }

  get featureClasses(): string {
    if (!this.args.isSinglePlanPaywall) {
      return '';
    }
    return `flex gap-x-12 ${this.smallNumberOfFeatures ? 'flex-col' : 'flex-wrap'}`;
  }

  get prefix(): string | undefined {
    return this.planData?.prefix;
  }

  get startingFromText(): string | undefined {
    if (!this.price || this.planData.hideStartingFromText) {
      return undefined;
    }

    if (this.planData?.startingFromSeatText) {
      return this.intl.t(this.planData?.startingFromSeatText, {
        price: this.perUnitPriceFormatted,
      });
    } else {
      return this.intl.t(this.planData?.startingFromText, {
        price: `$${this.price.preDiscountAmount / 100}`,
      });
    }
  }

  get price(): Price | undefined {
    if (this.args.plan.billableCustomerPlan || this.args.plan.activeTrialIsGraduating) {
      return undefined;
    }
    if (this.shouldUseQuoteService) {
      return this.quoteService.getQuoteById([this.args.plan.idAsNumber]);
    }
    return this.customerService.getPriceFromPlanIds([this.args.plan.idAsNumber]);
  }

  get perUnitPriceFormatted(): string | undefined {
    if (!this.price) {
      return '';
    }

    let charge = this.price.breakdown.firstObject.charges.find(
      (charge: any) => charge.isSeatMetric,
    );

    return charge ? `$${charge.per_unit_price / 100}` : '';
  }

  @action
  selectCard() {
    if (this.isInteractive) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'non_graduated_plan_card',
        place: 'paywall',
        plan_id: this.args.plan.idAsNumber,
      });
      this.args.selectPlan(this.args.plan.idAsNumber);
    }
  }

  get canSeeFinNewPaywallChanges(): boolean {
    return this.appService.app.canSeeFinPaywalls;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Paywalls::Comparison::PlanCard': typeof PlanCardComponent;
    'paywalls/comparison/plan-card': typeof PlanCardComponent;
  }
}
