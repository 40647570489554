/* import __COLOCATED_TEMPLATE__ from './features.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class Features extends Component {
  @service intl;
  @service appService;

  get title() {
    if (this.args.title) {
      return this.args.title;
    } else if (this.args.generateCustomTitle && this.args.productName) {
      return this.intl.t('paywalls.upgrade-modal.features.everything-you-get-with-product', {
        productName: this.args.productName,
      });
    }
    return this.intl.t('paywalls.upgrade-modal.features.everything-you-get-with-upgrade');
  }

  // This filters out plan.features if any of the plan.features contain a breakdown key of the same feature.key
  // Features with feature.breakdown_keys = product tours, sms, surveys (currently)
  // https://github.com/intercom/billing/blob/master/app/models/feature.rb
  // This means we use the feature nested keys in https://github.com/intercom/embercom/blob/master/translations/paywalls/upgrade-modal/features/en.yml
  // Instead of the root feature translations
  _removeFeaturesIfIncludedInBreakdown(features) {
    let breakdownKeys = features.flatMap((feature) => feature.breakdownKeys).compact();
    return features.filter((feature) => !breakdownKeys.includes(feature.key));
  }

  get features() {
    let filteredFeatureList = this._removeFeaturesIfIncludedInBreakdown(this.args.features);
    let billingFeatureList = filteredFeatureList.flatMap((feature) => {
      if (this.args.showBreakdown && isPresent(feature.breakdownKeys)) {
        return feature.breakdownKeys.map((breakdownKey) => {
          return {
            key: feature.key,
            name: this.intl.t(`paywalls.upgrade-modal.features.${feature.key}.${breakdownKey}`),
          };
        });
      }

      return {
        key: feature.key,
        name: this.intl.t(`paywalls.upgrade-modal.features.${feature.key}.feature-name`),
      };
    });

    let featureListForPaywall = billingFeatureList
      .concat(this.featuresDisplayedForMarketing)
      .sort((featureA, featureB) => featureA.key.localeCompare(featureB.key));

    return this._removeDuplicateFeatures(featureListForPaywall);
  }

  get featuresDisplayedForMarketing() {
    return isPresent(this.args.paywallMarketingFeaturesKeys)
      ? this.args.paywallMarketingFeaturesKeys.map((feature) => {
          return {
            key: feature,
            name: this.intl.t(`paywalls.upgrade-modal.features.${feature}.feature-name`),
          };
        })
      : [];
  }
  _removeDuplicateFeatures(features) {
    return features.filter((feature, index, self) => {
      return self.findIndex((f) => f.name === feature.name) === index;
    });
  }

  get displayFinPaywalls() {
    return this.appService.app.canSeeFinPaywalls;
  }
}
