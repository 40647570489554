/* import __COLOCATED_TEMPLATE__ from './sales-led-products.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  PLAN_DATA,
  CORE_ENGAGE_PRO_ID,
  CORE_SUPPORT_PRO_ID,
  CORE_CONVERT_PRO_ID,
} from 'embercom/lib/billing';
import BillingConstants from 'embercom/lib/billing';
import { action } from '@ember/object';

const TRANSLATION_ROOT_PATH = 'paywalls.upgrade-modal.sales-led-products.';

export default class SalesLedProducts extends Component {
  @service purchaseAnalyticsService;
  @service customerService;
  @service session;
  @service intl;
  @service appService;

  get coreEngageProId() {
    return CORE_ENGAGE_PRO_ID;
  }

  get currentCorePlan() {
    return this.customerService?.originalActivePlans
      ?.filter((plan) => plan.product.addon === false)
      ?.firstObject?.get('nameWithProduct');
  }

  get hasActiveSubscription() {
    return this.customerService?.customer?.hasActiveSubscription;
  }

  EXPANDED_CONTENT = {
    [CORE_SUPPORT_PRO_ID]: {
      nameTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.name`,
      subtitleTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.subtitle`,
      highlights: this.canSeeFinNewPaywallChanges
        ? [
            {
              titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.automated-support.title`,
              descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.automated-support.description`,
              finFeatureComponent: {
                icon: 'fin',
                descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.automated-support.fin`,
              },
            },

            {
              titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.human-support.title`,
              descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.human-support.description`,
            },
            {
              titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.proactive-support.title`,
              descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.proactive-support.description`,
            },
          ]
        : [
            {
              titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.human-support.title`,
              descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.human-support.description`,
            },
            {
              titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.self-serve-support.title`,
              descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.self-serve-support.description`,
            },
            {
              titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.proactive-support.title`,
              descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-support-pro-id.highlights.proactive-support.description`,
            },
          ],
    },
    [CORE_ENGAGE_PRO_ID]: {
      nameTranslationKey: `${TRANSLATION_ROOT_PATH}core-engage-pro-id.name`,
      subtitleTranslationKey: `${TRANSLATION_ROOT_PATH}core-engage-pro-id.subtitle`,
      highlights: [
        {
          titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-engage-pro-id.highlights.engage-adopt.title`,
          descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-engage-pro-id.highlights.engage-adopt.description`,
        },
        {
          titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-engage-pro-id.highlights.optimize.title`,
          descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-engage-pro-id.highlights.optimize.description`,
        },
        {
          titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-engage-pro-id.highlights.personalize.title`,
          descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-engage-pro-id.highlights.personalize.description`,
        },
      ],
    },
    [CORE_CONVERT_PRO_ID]: {
      nameTranslationKey: `${TRANSLATION_ROOT_PATH}core-convert-pro-id.name`,
      subtitleTranslationKey: `${TRANSLATION_ROOT_PATH}core-convert-pro-id.subtitle`,
      highlights: [
        {
          titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-convert-pro-id.highlights.conversion.title`,
          descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-convert-pro-id.highlights.conversion.description`,
        },
        {
          titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-convert-pro-id.highlights.manage-leads.title`,
          descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-convert-pro-id.highlights.manage-leads.description`,
        },
        {
          titleTranslationKey: `${TRANSLATION_ROOT_PATH}core-convert-pro-id.highlights.prospect.title`,
          descriptionTranslationKey: `${TRANSLATION_ROOT_PATH}core-convert-pro-id.highlights.prospect.description`,
        },
      ],
    },
  };

  get coreSalesLedProducts() {
    let desiredPlanIds = [CORE_SUPPORT_PRO_ID, CORE_ENGAGE_PRO_ID, CORE_CONVERT_PRO_ID];
    if (this.canSeeFinNewPaywallChanges) {
      desiredPlanIds = [CORE_ENGAGE_PRO_ID, CORE_SUPPORT_PRO_ID, CORE_CONVERT_PRO_ID];
    }
    return desiredPlanIds.map((planId) => {
      return Object.assign(
        {},
        BillingConstants.MODAL_ADD_PLAN_INFO[planId],
        this.EXPANDED_CONTENT[planId],
        {
          name: this.intl.t(PLAN_DATA[planId].marketingNameTranslationKey),
          planId,
        },
      );
    });
  }

  @action
  trackTalkToSalesClick() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'mini_pricing_modal',
      place: 'paywall',
      object: 'chat_with_us',
    });
  }

  get canSeeFinNewPaywallChanges() {
    return this.appService.app.canSeeFinPaywalls;
  }
}
