/* import __COLOCATED_TEMPLATE__ from './price-breakdown.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import numericFormatter from 'embercom/lib/numeric-formatter';
import { isEmpty } from '@ember/utils';

export default class PriceBreakdown extends Component {
  @service appService;
  @service customerService;
  @service quoteService;
  @service intl;

  get showBreakdown() {
    let canModifyPlan = this.customerService.customer.canModifyOrRemoveProducts;
    let isNotEarlyStage = !this.earlyStageCustomer;
    return canModifyPlan && isNotEarlyStage && this.args.price;
  }

  get price() {
    return this.args.price;
  }

  get breakdown() {
    return this.price.breakdown.firstObject;
  }

  get productName() {
    return this.breakdown?.name;
  }

  get onPricing5_X() {
    return this.quoteService.onPricing5_X;
  }

  get fixedPricing() {
    return (
      this.args.price?.breakdown?.firstObject?.charges?.firstObject?.pricing_metric === 'fixed'
    );
  }

  get billingPeriodDurationInMonths() {
    return this.customerService.customer.subscription.billingPeriodDurationInMonths;
  }

  get tooltipData() {
    let price = this.args.price;
    let breakdown = price?.breakdown?.firstObject;
    let { charges } = breakdown;
    if (!this.appService.app.canSeeFinPaywalls) {
      charges = charges.filter((charge) => charge.pricing_metric !== 'resolutions');
    }
    let summaryRows = charges
      .filter((charge) => charge.pricing_metric !== 'fixed')
      .map((charge) => new PricingMetric(charge, this.intl).tooltipSummary())
      .flat();
    let baseCharge = price?.breakdown.find(({ plan_id }) =>
      ['base_lite', 'base_standard'].includes(plan_id),
    );

    let discountRow = price.getDiscountInfo(this.billingPeriodDurationInMonths);

    return [
      {
        label: this.productName,
        metrics: [
          {
            label: this.intl.t('paywalls.upgrade-modal.price-breakdown.starting-price'),
            value: `$${numericFormatter(breakdown.plan_starting_price / 100, 2, true)}`,
          },
          ...summaryRows,
          {
            ...(baseCharge && {
              label: this.intl.t('paywalls.upgrade-modal.price-breakdown.base-charge'),
              value: `$${numericFormatter(baseCharge?.amount / 100, 2, true)}`,
            }),
          },
          { ...(!isEmpty(discountRow) && discountRow) },
          {
            label: this.intl.t('paywalls.upgrade-modal.price-breakdown.total'),
            value: price.formattedAmountToTwoDecimals,
          },
        ],
      },
    ];
  }
}
